.Helper-box {
  position: absolute;
  top: 25%;
  height: fit-content;
  width: 45vw;
  padding: 0.3vw;
  border-radius: 0.5vw;
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: -0.1vw 0.1vw 0.7vw rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.Helper-nav {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 1vw;
}

.Helper-close {
  background-color: rgba(195, 195, 195, 0);
  border: none;
  padding: 0;
  margin: 0.25vw;
  width: 2vw;
  height: 2vw;
  border-radius: 2.5vw;
  font-size: 2vw;
  line-height: 2vw;
  cursor: pointer;
}

.Helper-close:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
}

.Helper-close:hover {
  color: rgb(255, 42, 0);
}

.Helper-content {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  padding: 1vw 5vw;
  height: fit-content;
  overflow-y: auto;
  font-size: 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
}



.Helper-content::-webkit-scrollbar {
  display: none;
}

.Helper-content h3 {
  color: rgb(255, 42, 0);
  font-size: 1.5rem;
}

.lang-box {
  display: flex;
}

.lang-box button {
  background-color: rgba(195, 195, 195, 0);
  color: rgb(255, 255, 255);
  border: none;
  height: 1.5vw;
  width: 4vw;
  border-radius: 2vw;
  margin: 0.25vw;
  font-size: 0.8vw;
  line-height: 1.5vw;
  cursor: pointer;
  transition: 0.2s;
}

.lang-box button:hover {
  text-decoration: underline;
}

.selected {
  color: rgb(255, 255, 255) !important;
  text-decoration: underline;
}

@media (max-width: 801px) {
  .Helper-box {
    height: 90vh;
  }
  .Helper-content {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }

  .Helper-content h3 {
    line-height: 2rem;
  }

  .Helper-close {
    transform: scale(2.5);
  }
  .Helper-nav {
    padding: 3vw 1vw;
  }

  .lang-box button {
    width: 13vw;
    height: 4vw;
    font-size: 3vw;
    margin: 1vw;
  }
}
