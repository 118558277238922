.loginScreen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 10vh 0
}

.lang-box-login {
    position: absolute;
    top: 5px;
    right: 1vw
}

.loginScreenForm {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    max-width: 1000px
}

.loginScreenForm div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0
}


.loginScreenForm label {
    width: 250px ;
}

.loginScreenForm input {
    appearance: none;
    width: 250px ;
    height: 25px;
    border-radius: 5px;
    border: 1px solid rgb(57, 57, 57)  !important;

}

.loginScreenForm input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgb(248, 248, 248) inset;
    -webkit-text-fill-color:  rgb(57, 57, 57)   !important;
}

.loginScreenForm button {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    margin-top: 15px 0;
    outline: 0;
    border: 0;
    font-weight: 600;
    background-color:rgb(255, 42, 0,1);
    color: rgb(0,0, 0,0);
    border: 1px solid white;
    cursor: pointer;
    transition: .2s;
}

.loginScreenForm button:hover {
    width: 100px;
    height: 35px;
    color: rgb(0, 0, 0,1);
    background-color:rgb(255, 42, 0,0.2);
    border-left: 5px solid rgb(255, 42, 0,1);
    border-right: 5px solid rgb(255, 42, 0,1);
}

.errorMessage {
    margin: 5px;
    font-size: 12px;
    text-align: center;
    color: rgb(255, 42, 0);
}