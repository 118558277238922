@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300&family=Nunito:wght@200;300;400;500;600;700;800&family=Roboto:wght@100;300;400;500;700&display=swap');

button {
  font-family: "Nunito", "M PLUS Rounded 1c", sans-serif;
}

.App {
  width: 100vw;
  max-width: 100vw;
  height: 47vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  padding-top: 1vw;
}



.helper-box {
  position: absolute;
  z-index: 200;
  max-width: 100%;
  top: 5vw;
  right: 0.5vw;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
}

.helper {
  width: 4vw;
  height: 4vw;
  min-width: 30px;
  min-height: 30px;
  font-size: clamp(12px, 2.5vw, 2.5vw); 
  line-height:clamp(30px,4vw, 4vw); 
  border-radius: 4vw;
  margin-right: 2vw;
  margin-bottom: 1vw;
  color: rgb(255, 42, 0, 0.8);
  background-color: rgba(255, 255,255, 0);
  transition: 0.5s;
  border: none;
  cursor: pointer;
  animation-name: helper-loop;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: relative;
}

.link-to {
  text-decoration: none;
  text-align: center;
  line-height: 4vw;
  width: 4vw;
  height: 4vw;
  min-width: 30px;
  min-height: 30px;
  font-size: clamp(8px,1vw, 1vw); 
  line-height:clamp(30px,4vw, 4vw); 
  border-radius: 4vw;
  margin-right: 2vw;
  margin-bottom: 1vw;
  color: white;
  background-color: rgb(255, 42, 0);
  transition: 0.5s;
  border: none;
  cursor: pointer;
}


.link-story, .link-module, .link-home {
  text-decoration: none;
  text-align: center;
  display: inline-block;
  width: 4vw;
  max-width: 80px;
  height: 4vw;
  max-height: 80px;
  min-width: 30px;
  min-height: 30px;
  font-size: clamp(7px,0.9vw, 24px); 
  line-height:clamp(30px,4vw, 80px); 
  border-radius: 100%;
  color: white;
  background-color:  rgb(255, 42, 0, 0.5);
  background-size: 200%;
  background-position: center;
  transition: 0.5s;
  border: none;
  cursor: pointer;
  position: relative;
  margin: 2vw;
}

.link-home:hover {
  background: url(./Images/earth.png);
  background-repeat: no-repeat;
  background-size: 150%;
  background-position: center;
  font-weight: 600;
  letter-spacing: 0.7px;
}

.link-home:after {
  content: '';
  position: absolute;
  bottom: -15%;
  left: -10%;
  width: 120%;
  height: 120%;
  border-radius: 100%;
  min-width: 34px;
  min-height: 34px;
  border-top: 0.2vw solid rgb(255, 42, 0, 0.5);
  border-bottom: 0.2vw solid rgb(255, 42, 0, 0.5);
  z-index: -10;
  opacity: 1;
  transform: rotate(45deg);
  transition: 0.5s;
}

.link-home:hover:after {
  transform: rotate(-45deg);
}


.link-module:hover {
  background: url(./Images/link-moon.png);
  background-repeat: no-repeat;
  background-size: 48%;
  background-position: right;
  font-weight: 600;
  letter-spacing: 0.7px;
}

.link-module:after {
  content: '';
  position: absolute;
  bottom: -15%;
  left: -10%;
  width: 120%;
  height: 120%;
  border-radius: 100%;
  min-width: 34px;
  min-height: 34px;
  border-top: 0.2vw solid rgb(255, 42, 0, 0.5);
  border-bottom: 0.2vw solid rgb(255, 42, 0, 0.5);
  z-index: -10;
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.5s;
}

.link-module:hover:after{
  content: '3D';
  display: inline;
  padding:0;
  line-height: clamp(14px,1.4vw, 1.4vw); 
  font-size: clamp(6px,0.6vw, 0.6vw); 
  bottom: 15%;
  left: -20%;
  opacity: 1;
  width: 30%;
  height: 30%;
  min-width: 30%;
  min-height: 30%;
  background-color: rgba(241,90,36);
  border-top: 0vw solid rgb(255, 42, 0, 0.5);
  border-bottom: 0vw solid rgb(255, 42, 0, 0.5);
  transform: rotate(-10deg);
 }

.link-module:before {
  content: '';
  position: absolute;
  top: -5%;
  left: -5%;
  width: 120%;
  height: 120%;
  z-index: -10;
  background: url(./Images/link-module.png);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transform: scale(0.1) translateX(-2vw) rotate(10deg);
  transition: 1s;
}

.link-module:hover:before {
  opacity: 1;
  transform: scale(1.1) translateX(-0.9vw) rotate(-5deg);;
 }

.link-story:hover {
  background: url(./Images/link-moon.png);
  background-repeat: no-repeat;
  background-size: 87%;
  background-position: center;
  font-weight: 600;
  letter-spacing: 0.7px;
}


.link-story:before {
  content: '';
  position: absolute;
  top: -40%;
  left: -30%;
  width: 170%;
  height: 170%;
  z-index: -10;
  background: url(./Images/link-story-white.png);
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transform: scale(0.8) translateX(-2vw) rotate(-5deg);
  transition: 0.5s;
}

.link-story:after {
  content: '';
  position: absolute;
  top: -10%;
  left: -12%;
  width: 120%;
  height: 120%;
  border-radius: 100%;
  min-width: 34px;
  min-height: 34px;
  border-left: 0.2vw solid rgb(255, 42, 0, 0.5);
  border-right: 0.2vw solid rgb(255, 42, 0, 0.5);
  z-index: -10;
  opacity: 1;
  transition: 0.5s;
}

.link-story:hover {
  background: url(./Images/link-moon.png);
  background-repeat: no-repeat;
  background-size: 87%;
  background-position: center;
  font-weight: 600;
  letter-spacing: 0.7px;
}

.link-story:hover:before {
  opacity: 1;
  transform: scale(1.1) translateX(0) rotate(0);;
 }

 .link-story:hover:after {
  opacity: 0;
  transform: rotate(90deg);
  border-color: rgba(241,90,36);
 }

.sound {
  width: 4vw;
  height: 4vw;
  min-width: 30px;
  min-height: 30px;
  font-size: clamp(10px,1vw, 1vw); 
  line-height:clamp(30px,4vw, 4vw); 
  font-size: 0.7vw;
  border-radius: 4vw;
  margin-right: 2vw;
  margin-bottom: 1vw;
  color: rgb(255, 42, 0, 0.5);
  background-color: rgba(195, 195, 195, 0.1);
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.sound img {
  width: 2.5vw;
  min-width: 20px
}

.sound:hover {
  background-color: rgba(195, 195, 195, 0.05);
}

.sound:hover img {
  filter: brightness(110%);
}

.helper:hover, .link-to:hover {
  background-color: rgba(195, 195, 195, 0.2);
  color: rgb(255, 42, 0);
}

.music-tribute{
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  font-size: clamp(10px, 10px, 0.7vw);
}

.music-tribute a:visited {
 color:white
}

.Main-screen {
  height: 85%;
  width: 95vw;
  max-width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  background-image: url("./Images/daniel-olah-EarthSpace.jpg");
  background-position: 50% 0;
  animation-name: Main-screen-story-loop;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: relative;
}

.Bottom-hider {
  position: absolute;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 97%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100vw;
  height: 100vh;
  top: 45vw;
  z-index: 10;
}

.title_fan {
  color: rgb(255, 42, 0);
  font-size: 2vw;
}

.modal_box {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 20%;
  position: absolute;
  top: 0;
  z-index: 100;
}

.ispace_init {
  display: flex;
  flex-direction: column;
  height: 20vw;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}

.ispace_nav {
  width: 92%;
  margin: 0.2vw;
  justify-content: space-between;
  position: absolute;
  top: 1vw;
  z-index: 500;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.ispace_nav div {
  width: 13vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.ispace_nav div span {
  font-size: 1vw;
  margin-left: 0.5vw;
}

.ispace_logo {
  width: 5vw;
}

.hakuto_logo {
  width: 1.5vw;
}

.player_box {
  position: absolute;
  bottom: 2%;
  z-index: 130;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.player_box button {
  padding: 0;
  width: 3vw;
  height: 3vw;
  border-radius: 3vw;
  font-size: 1.5vw;
  line-height: 3vw;
  border: none;
  margin: 0.1vw;
  background-color: rgba(195, 195, 195, 0);
  cursor: pointer;
  color: rgb(255, 42, 0);
  transition: 0.5s;
}

.player_box button:hover {
  background-color: rgba(195, 195, 195, 0.2);
}

.success_box {
  position: absolute;
  width: 28%;
  color: white;
  transition: 0.2s;
}

.success_box_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.success_box_top button {
  width: 1.8vw;
  height: 1.8vw;
  border-radius: 2vw;
  font-size: 1.5vw;
  position: relative;
  background-color: rgba(255, 42, 0, 0.7);
  color: white;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  animation-name: success-button-loop;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.success_box_top button:hover {
  background-color: rgba(255, 42, 0, 1);
}

.success_box h2 {
  margin: 1px;
  font-size: 1.7vw;
}

.success_box h3 {
  margin: 5px;
  font-size: 1.1vw;
}

.success_text {
  border-radius: 1vw;
  padding: 0.5vw; /*10px = 0.5vw*/
  border-bottom: 0.05vw solid rgb(255, 42, 0);
  border-top: 0.05vw solid rgb(255, 42, 0);
  text-shadow: rgba(255, 204, 0, 0.6) 1px 0 10px;
  color: white;
  font-size: 1vw;
  font-weight: 100;
  background: rgb(5, 0, 68);
  background: linear-gradient(
    60deg,
    rgba(5, 0, 68, 0.4) 0%,
    rgba(60, 96, 255, 0.2) 24%,
    rgba(5, 0, 68, 0.5) 38%,
    rgba(74, 104, 238, 0.3) 64%,
    rgba(5, 0, 68, 0.5) 100%
  );
  background-size: 400% 400%;
  animation-name: success-text-loop;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transition: 2s;
}

.success_text_left {
  border-left: 0.5vw solid rgb(255, 42, 0);
}

.success_text_right {
  border-right: 0.5vw solid rgb(255, 42, 0);
}

.success_box ul {
  margin: 0.1vw;
  list-style: none;
}

.success_box ul li::before {
  content: "\2022";
  color: rgb(255, 60, 0);
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.rocket_light {
  position: absolute;
  width: 1vw;
  height: 1vw;
  border-radius: 1vw;
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 242, 0, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.hakuto_booster {
  position: absolute;
  width: 2.5vw;
  transform: rotate(-90deg);
}

.hakuto_module {
  position: absolute;
  width: 5vw;
}

.hakuto_thrust {
  position: absolute;
  animation-name: thrust-loop;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  transition: 0.2s;
}

.hakuto_module_box {
  position: absolute;
  width: 5vw;
  height: 5vw;
  animation-name: module-loop;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.earth-static {
  animation-name: earth-loop;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.moon-static {
  transition: 0.2s;
  animation-name: moon-loop;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.landing-zone {
  position: absolute;
  width: 7vw;
  height: 7vw;
  border-radius: 7vw;
  background-color: rgba(0, 0, 0, 0.05);
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 0.2vw 100%, 100% 0.2vw, 0.2vw 100%, 100% 0.2vw;
  background-repeat: no-repeat;
  filter: drop-shadow(0 0 1vw rgb(14, 0, 120));
  background-image: repeating-linear-gradient(
      45deg,
      rgb(14, 0, 120),
      rgb(14, 0, 120) 10px,
      transparent 10px,
      transparent 20px
    ),
    repeating-linear-gradient(
      135deg,
      rgb(14, 0, 120),
      rgb(14, 0, 120) 10px,
      transparent 10px,
      transparent 20px
    ),
    repeating-linear-gradient(
      225deg,
      rgb(14, 0, 120),
      rgb(14, 0, 120) 10px,
      transparent 10px,
      transparent 20px
    ),
    repeating-linear-gradient(
      315deg,
      rgb(14, 0, 120),
      rgb(14, 0, 120) 10px,
      transparent 10px,
      transparent 20px
    );
  z-index: 2;
  animation-name: landing-loop;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transition: 0.2s;
}

.landing-zone-finished {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 0.2vw 100%, 100% 0.2vw, 0.2vw 100%, 100% 0.2vw;
  background-repeat: no-repeat;
  filter: drop-shadow(0 0 1vw rgb(255, 60, 0));
  background-image: repeating-linear-gradient(
      45deg,
      rgb(255, 60, 0),
      rgb(255, 60, 0) 10px,
      transparent 10px,
      transparent 20px
    ),
    repeating-linear-gradient(
      135deg,
      rgb(255, 60, 0),
      rgb(255, 60, 0) 10px,
      transparent 10px,
      transparent 20px
    ),
    repeating-linear-gradient(
      225deg,
      rgb(255, 60, 0),
      rgb(255, 60, 0) 10px,
      transparent 10px,
      transparent 20px
    ),
    repeating-linear-gradient(
      315deg,
      rgb(255, 60, 0),
      rgb(255, 60, 0) 10px,
      transparent 10px,
      transparent 20px
    );
  z-index: 2;
  animation-name: landing-loop;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transition: 0.2s;
}

.lunar-orbit {
  position: absolute;
  width: 14vw;
  height: 14vw;
  border-radius: 15vw;
  border-top: 0.4vw solid rgb(193, 224, 244);
  border-left: 0.2vw solid rgb(193, 224, 244);
  transform: scale(3, 1) rotate(45deg);
  filter: blur(3px);
  transition: 0.2s;
  animation-name: moon-orbit-loop;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.lunar-orbit-static {
  position: absolute;
  top: 35%;
  left: 47%;
  transform: rotate(25deg);
}

.hakuto_module_rotation {
  position: absolute;
  width: 5vw;
  height: 5vw;
}

.signal {
  position: absolute;
  width: 10vw;
  height: 10vw;
  border-radius: 10vw;
  border: 0.25vw solid rgb(65, 201, 255);
  filter: blur(0.2vw);
  bottom: 5%;
  right: -50%;
}

.signal-static {
  animation-name: signal-loop;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transition: 0.2s;
}

.signale {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 2px solid rgb(65, 201, 255);
  filter: blur(1px);
  bottom: 18%;
  left: 8%;
}

.signale-static {
  animation-name: signal-loop;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transition: 0.2s;
  animation-delay: 250ms;
}

.orbit {
  position: absolute;
  width: 20vw;
  height: 20vw;
  border-radius: 20vw;
  border-top: 0.5vw solid rgb(53, 178, 255);
  transform: skew(-40deg, 15deg);
  filter: blur(3px);
  transition: 0.2;
}

.orbit_finished {
  position: absolute;
  width: 5vw;
  height: 5vw;
  border-radius: 5vw;
  border-top: 0.3vw solid rgb(53, 178, 255);
  filter: blur(3px);
  transition: 0.2;
}

.orbit_static {
  animation-name: orbit-loop;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transition: 0.2s;
}

.orbit_start {
  position: absolute;
  left: 60%;
  bottom: 20%;
  width: 7vw;
  height: 7vw;
  border-radius: 7vw;
  border-top: 2vw solid rgb(53, 178, 255);
  transform: rotate(45deg) scale(4, 1);
  filter: blur(4px);
  transition: 0.2;
}

.Slider-screen {
  height: 8vh;
  width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  /*filter: drop-shadow(0 0 3px rgb(145, 145, 145));*/
  border-radius: 20px;
  position: relative;
  z-index: 11;
}

.Slider-box {
  width: 90%;
  display: flex;
  justify-content: center;
}

.mission_two {
  position: absolute;
  z-index: 500;
  flex-direction: column;
  align-items: center;
}

.mission_two span {
  font-size: 1.8vw;
}

.mission_two h4 {
  font-size: 3vw;
  margin: 0;
}

.mission_two h3 {
  margin: 0;
  font-size: 6vw;
}

.mission_two h3 a:link {
  color: rgb(255, 107, 62);
  text-decoration: none;
  transition: 0.1s;
}

.mission_two h3 a:visited {
  color: rgb(255, 60, 0);
  text-decoration: none;
}

.mission_two h3 a:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.mission_two h3 a:active {
  color: rgb(255, 60, 0);
  text-decoration: none;
}

.photo_1_box {
  position: absolute;
  display: flex;
  height: 21vw;
  left: 5%;
  top: 15%;
  transition: 0.5s;
}

.photo_1_pointer {
  width: 20vw;
  height: 5vw;
  border-top: 0.15vw solid rgba(255, 68, 0, 0.9);
  position: relative;
  top: 30%;
  left: -5.8vw;
}

.photo_1_circle {
  width: 5vw;
  height: 5vw;
  border-radius: 5vw;
  border: 0.1vw solid rgba(255, 68, 0, 0.9);
  position: relative;
  left: -6.1vw;
  top: 23%;
}

.photo_1 {
  height: 20vw;
  position: relative;
  z-index: 100;
  border-radius: 5%;
}

.photo_1_caption {
  font-size: 1vw;
  color: white;
  position: absolute;
  z-index: 101;
  bottom: -1%;
  left: 1%;
  font-weight: 100;
}

.record_box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 21vw;
  width: 40vw;
  top: 15%;
  transition: 0.5s;
}

.record_pointer {
  width: 2vw;
  height: 5vw;
  border-left: 0.15vw solid rgba(255, 68, 0, 0.9);
  position: relative;
  bottom: -1vw;
}

.record_circle {
  width: 5vw;
  height: 5vw;
  border-radius: 5vw;
  border: 0.1vw solid rgba(255, 68, 0, 0.9);
  position: relative;
  bottom: -1vw;
  right: 1vw;
}

.record_caption {
  font-size: 1vw;
  color: white;
  position: relative;
  z-index: 101;
  font-weight: 100;
}

.cookie_box {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  top: 19.2%;
  right: 0.57%;
  transition: 0.5s;
}

.cookie_pointer {
  width: 2vw;
  height: 5vw;
  border-left: 0.15vw solid rgba(255, 68, 0, 0.9);
  position: relative;
  bottom: -1vw;
}

.cookie_circle {
  width: 6vw;
  height: 6vw;
  border-radius: 6vw;
  border: 0.1vw solid rgba(255, 68, 0, 0.9);
  position: relative;
  bottom: -1vw;
  right: 1vw;
}

.cookie_caption {
  font-size: 0.8vw;
  width: 25vw;
  color: white;
  position: relative;
  z-index: 101;
  font-weight: 100;
  top: 1vw;
}

.landing_trivia_box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 21vw;
  width: 42vw;
  top: 10%;
  right: 5%;
  transition: 0.5s;
}

.landing_trivia_pointer {
  width: 12vw;
  height: 5vw;
  border-top: 0.15vw solid rgba(255, 68, 0, 0.9);
  position: relative;
  bottom: -1vw;
  z-index: 100;
  transform: rotate(10deg);
}

.landing_trivia_caption {
  width: 28vw;
  font-size: 1vw;
  color: white;
  position: relative;
  z-index: 101;
  font-weight: 100;
  top: 2vw;
}

.photo_2_box {
  position: absolute;
  display: flex;
  transition: 0.5s;
}

.photo_2_pointer {
  width: 2vw;
  height: 5vw;
  border-top: 0.15vw solid rgba(255, 68, 0, 0.9);
  position: relative;
}

.photo_2_circle {
  width: 14vw;
  height: 2.5vw;
  border-radius: 5vw;
  border: 0.1vw solid rgba(255, 68, 0, 0.9);
  position: relative;
  top: -0.8vw;
  left: 1vw;
}

.photo_2 {
  width: 75vw;
  position: relative;
  z-index: 600;
  border-radius: 5%;
  top: 2vw;
  left: 7.5vw;
}

.photo_2_caption {
  font-size: 1vw;
  color: white;
  position: absolute;
  z-index: 101;
  top: 0;
  left: 10vw;
  font-weight: 100;
}

.hint_box {
  position: absolute;
  bottom: 2%;
  right: 2%;
  display: flex;
  align-items: center;
}

.hint-explain {
  color: rgba(255, 68, 0, 1);
  margin-right: 1vw;
}

.hint {
  width: 4vw;
  height: 4vw;
  border-radius: 4vw;
  font-size: 3vw;
  text-align: center;
  font-weight: bold;
  color: rgba(255, 68, 0, 1);
  animation-name: helper-loop;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  position: relative;
  transition: 0.2s;
}

.hint:hover {
  background-color: rgba(255, 68, 0, 1);
  color: white;
}

.photo_3_box {
  position: absolute;
  display: flex;
  transition: 0.5s;
  width: 100vw;
  height: 45vw;
}

.photo_3_pointer {
  width: 40vw;
  height: 5vw;
  border-bottom: 0.13vw solid rgba(255, 68, 0, 0.9);
  border-right: 0.13vw solid rgba(255, 68, 0, 0.9);
  position: absolute;
  top: 32vw;
  left: 17.2vw;
}

.photo_3_circle {
  width: 28vw;
  height: 28vw;
  border-radius: 28vw;
  border: 0.1vw solid rgba(255, 68, 0, 0.9);
  position: absolute;
  left: -10vw;
  top: 28vw;
}

.photo_3 {
  width: 30vw;
  position: relative;
  z-index: 100;
  border-radius: 5%;
  position: absolute;
  top: 5vw;
  left: 53vw;
}

.photo_3_caption {
  width: 25vw;
  font-size: 1vw;
  color: white;
  position: absolute;
  z-index: 101;
  font-weight: 100;
  top: 35vw;
  left: 58vw;
}


@media (prefered-motion: no-preference) {
  .Image-box-anim {
    animation: Image-box-spin infinite 40s linear;
  }
}

@keyframes Image-box-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Main-screen-story-loop {
  0% {
    background-size: 88vw auto;
  }
  50% {
    background-size: 95vw auto;
  }
  100% {
    background-size: 88vw auto;
  }
}

@keyframes module-loop {
  0% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(91deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(89deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes thrust-loop {
  0% {
    width: 1.1vw;
    filter: blur(0.7px);
  }
  25% {
    width: 1.12vw;
    filter: blur(1px);
  }
  50% {
    width: 1.1vw;
    filter: blur(0.7px);
  }
  75% {
    width: 1.12vw;
    filter: blur(1px);
  }
  100% {
    width: 1.1vw;
    filter: blur(0.6px);
  }
}

@keyframes success-text-loop {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes signal-loop {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes orbit-loop {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes earth-loop {
  0% {
    filter: brightness(0.9) drop-shadow(-3px -1px rgba(1, 1, 255, 0));
  }
  50% {
    filter: brightness(1.3) drop-shadow(-3px -3px rgba(1, 115, 255, 0.4));
  }
  100% {
    filter: brightness(0.9) drop-shadow(-3px -1px rgba(1, 1, 255, 0));
  }
}

@keyframes moon-loop {
  0% {
    filter: brightness(0.9);
  }
  50% {
    filter: brightness(1.1);
  }
  100% {
    filter: brightness(0.9);
  }
}

@keyframes moon-orbit-loop {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes landing-loop {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(0.4);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}

@keyframes helper-loop {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes success-button-loop {
  0% {
    transform: rotate(0deg);
    outline: 5px solid rgba(255, 0, 0, 0);
    top: 0;
    right: 0;
  }
  25% {
    transform: rotate(-10deg);
    outline: 5px solid rgba(255, 0, 0, 0.2);
    top: -0.5vw;
    right: -0.5vw;
  }
  50% {
    transform: rotate(0deg);
    outline: 5px solid rgba(255, 0, 0, 0);
    top: 0;
    right: 0;
  }
  75% {
    transform: rotate(10deg);
    outline: 5px solid rgba(255, 0, 0, 0.2);
    top: -0.6vw;
    right: -0.3vw;
  }
  100% {
    transform: rotate(0deg);
    outline: 5px solid rgba(255, 0, 0, 0);
    top: 0;
    right: 0;
  }
}

@media (max-width: 801px) {
  .player_box button {
    transform: scale(1.8);
  }
}

@media screen and (max-height: 601px) {
  .App {
    transform: scale(0.9);
    position: relative;
    bottom: 2vh;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .App::-webkit-scrollbar {
    display: none;
  }

  .player_box button {
    transform: scale(1.2);
  }
}
