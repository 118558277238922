input[type="range"] {
  overflow: hidden;
  height: 1.7vw;
  -webkit-appearance: none;
  margin: 0.5vw 0;
  background: rgba(255, 255, 255, 0);
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.4vw;
  cursor: pointer;
  animate: 0.2s;
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 1.2vw;
  width: 1.2vw;
  border-radius: 1vw;
  background: #ff0000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.4vw;
  animate: 0.2s;
  outline: 0px solid rgba(255, 0, 0, 0.5);
  transition: 0.2s;
}

input[type="range"]::-webkit-slider-thumb:hover {
  outline: 5px solid rgba(255, 0, 0, 0.3);
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: rgba(255, 255, 255, 0);
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 0.4vw;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: rgba(255, 255, 255, 0);
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ff0000;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-thumb {
  height: 1.2vw;
  width: 1.2vw;
  border-radius: 1vw;
  background: #ff0000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.4vw;
}

.slider {
  position: relative;
  z-index: 130;
}

.slider_range {
  position: absolute;
  width: 89%;
  height: 0.5vw;
  margin-top: 1.1vw;
  border-radius: 2vw;
  background-color: rgb(224, 224, 224);
  z-index: 120;
}

.slider_lower {
  height: 0.5vw;
  border-radius: 2vw;
  background-color: #ff5c5c;
}

.slider_buttons {
  position: absolute;
  height: 2vw;
  margin-top: 1.8vw;
  left: 1.1%;
  z-index: 120;
  display: flex;
  justify-content: space-evenly;
}

.slider_buttons button {
  width: 5vw;
  height: 1.5vw;
  margin: 0 1.72vw;
  font-size: 0.8vw;
  border-radius: 4.45vw;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: rgba(255, 75, 39, 0);
  font-weight: 100;
  transition: 0.2s;
}

.slider_buttons button:hover {
  background-color: rgba(255, 75, 39, 0.2);
}

@media (max-width: 801px) {
  .slider_buttons button {
    font-size: 1vw;
    width: 6vw;
    margin: 0 1.225vw;
  }
}
