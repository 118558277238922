.InfoScreen_box {
  /*background-color: rgba(255, 75, 39, 0.9);*/
  width: 30%;
  height: 100%;
  border-radius: 1vw;
  color: rgba(255, 75, 39, 1);
  position: absolute;
  right: 0;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: inset 0px 0px 5px rgba(193, 193, 193, 0.8);
}

.InfoScreen_nav {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 3vw;
  border-radius: 1vw 1vw 0 0;
  background-color: rgba(255, 75, 39, 1);
}

.InfoScreen_nav h3 {
  margin-left: 1vw;
}

.InfoScreen_close {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  border: none;
  padding: 0;
  margin: 0 0.2vw;
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 2.5vw;
  font-size: 2vw;
  line-height: 2.6vw;
  cursor: pointer;
  transition: 0.2s;
}

.InfoScreen_close:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
}

.InfoScreen_close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.InfoScreen_content {
  width: 100%;
  font-size: 1vw;
}

.InfoScreen_header {
  background-color: rgba(255, 75, 39, 1);
  color: white;
  padding: 0 0.5vw;
}

.InfoScreen_content h3 {
  font-size: 2vw;
  margin: 0;
  margin-bottom: 1vw;
}

.article_box {
  padding: 0 0.5vw;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 31vw;
}

.article_box div {
  display: flex;
  flex-direction: column;
  padding-bottom: 1vw;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.article_box div span {
  display: flex;
  flex-direction: column;
  font-size: 1.3vw;
  font-weight: bold;
}

.article_box div a:link {
  color: rgba(255, 75, 39, 1);
  border-radius: 0.3vw;
  text-decoration: none;
  transition: 0.1s;
}

.article_box div a:visited {
  color: rgba(255, 75, 39, 1);
  text-decoration: none;
}

.article_box div a:hover {
  text-decoration: none;
  background-color: rgba(255, 75, 39, 0.2);
}

.article_box div a:active {
  color: rgba(255, 75, 39, 1);
  text-decoration: none;
}

.infoscreen_photo_1 {
  width: 100%;
  border-radius: 4%;
}

@keyframes infoscreen-loop {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
