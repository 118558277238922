.App-module {
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: hidden;
    padding-top: 1vw;
    background-color: black;
  }
  
.modul-scene {
    width: 100vw;
    height: 100vh;
}

.label > div
{
  position: absolute;
  display: inline-block;
  font-size: 4em;
  text-align: center;
  border-radius: 5vw;
  padding: 1.5vw 4vw; 
  width: fit-content;
  border-bottom: 0.4vw solid rgb(255, 42, 0);
  border-top: 0.4vw solid rgb(255, 42, 0);
  border-left: 1.5vw solid rgb(255, 42, 0);
  text-shadow: rgba(255, 204, 0, 0.6) 1px 0 10px;
  color: white;
  font-weight: 100;
  background: rgb(5, 0, 68);
  background: linear-gradient(
    60deg,
    rgba(5, 0, 68, 0.4) 0%,
    rgba(60, 96, 255, 0.2) 24%,
    rgba(5, 0, 68, 0.5) 38%,
    rgba(74, 104, 238, 0.3) 64%,
    rgba(5, 0, 68, 0.5) 100%
  );
  background-size: 400% 400%;
}
