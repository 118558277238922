.home {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow-y: scroll;
    overflow-x: hidden;
}

.section_nav {
    width: 98vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 100;
    box-sizing: border-box;
    padding: 1vh 1vw 0 1vw;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0) 100%);
}

.nav-box {
    opacity: 0;
}

    .nav-box button {
        outline: none;
        border: none;
        width: 1.5vw;
        height: 1.5vw;
        border-radius: 1.5vw;
        margin: 0 0.5vw;
        text-wrap: nowrap;
        background-color:rgb(255, 42, 0, 0.5);
        color: rgb(255, 255, 255, 0);
        overflow: hidden;
        cursor: pointer;
        letter-spacing: 0.5rem;
        font-weight: 400;
        transition: 0.5s;
    }

    .nav-box button:hover {
        width: 15vw;
        color: rgb(255, 255, 255,1);
        background-color:rgb(255, 42, 0, 0.2);
        border-left: 5px solid rgb(255, 42, 0, 1);
        border-right: 5px solid rgb(255, 42, 0, 1);
        letter-spacing: 0.3rem;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
    }

.nav-box-visible {
    animation: nav-visible 2s ease-in 1 normal forwards;
    opacity: 0;
}

@keyframes nav-visible {
    0% {
        opacity: 0 !important;
    }
    100% {
        opacity: 1 !important;
    }
}

.lang-box {
    background-color:rgb(255, 42, 0, 0.5);
    width: fit-content;
    border-radius: 10px;
}

.section_top {
    position: relative;
    width: 96vw;
    min-height: 85vh;
    scroll-margin-top: 100px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 5vh;
    margin-bottom: 1vh;
    border-radius: 25vw;
    border-left: 0.3vw solid rgb(255, 42, 0);
    border-right: 0.3vw solid rgb(255, 42, 0);
    box-shadow: 0 0 25px rgba(50, 50, 50, 0.75);
}

.planet {
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: rgba(255, 255, 255,0);
    border-radius: 100%;
    font-size: 0.95rem;
    letter-spacing: 0.9rem;
    text-indent: 0.9rem;
    outline: none;
    border: none;
    transition: 0.5s;
}

    .planet div {
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: 0.4s;
}

.planet:hover {
    color: rgba(255, 255, 255,1);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
    font-size: 0.9rem;
    letter-spacing: 0.8rem;
    text-indent: 0.8rem;
}


.planet__a {
    width: 50px;
    height: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: planet__a_anim-init 4s ease-in-out 1 normal forwards, planet__a_anim 9s ease-in-out infinite alternate; 
}

.planet__a div {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 80, 0, 0.9);
    transition: 1s;
}

.planet__a:hover .planet__inner {
    width: 2.5vw;
    height: 2.5vw;
}

        @keyframes planet__a_anim-init {
            0% {
                width: 0vw;
                height: 0vw;
                opacity: 0;
            }
            100% {
                width: 5vw;
                height: 5vw;
                opacity: 1
            }
        }
        @keyframes planet__a_anim {
            0% {
                left: 0;
            }
            100% {
                left: 2vw;
            }
        }
        
.planet__b {
    width: 10vw;
    height: 10vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: planet__b_anim-init 4s ease-in-out 1 normal forwards, planet__b_anim 10s ease-in-out infinite alternate;
}

    .planet__b div {
        width: 4vw;
        height: 4vw;
        background-color: rgba(255, 80, 0, 0.75);
    }

    .planet__b:hover .planet__inner {
        width: 5vw;
        height: 5vw;
    }

        @keyframes planet__b_anim-init {
            0% {
                width: 0vw;
                height: 0vw;
                opacity: 0;
            }
            100% {
                width: 10vw;
                height: 10vw;
                opacity: 1;
            }
        }
        @keyframes planet__b_anim {
            0% {
                left: 0;
            }
            100% {
                left: 2vw;
            }
        }

.planet__c {
    width: 15vw;
    height: 15vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: planet__c_anim-init 4s ease-in-out 1 normal forwards, planet__c_anim 12s ease-in-out infinite alternate; 
}

.planet__c div {
    width: 7vw;
    height: 7vw;
    background-color: rgba(255, 80, 0, 0.5);
}

.planet__c:hover .planet__inner {
    width: 8vw;
    height: 8vw;
}

        @keyframes planet__c_anim-init {
            0% {
                width: 0vw;
                height: 0vw;
                opacity: 0;
            }
            100% {
                width: 15vw;
                height: 15vw;
                opacity: 1;
            }
        }
        @keyframes planet__c_anim {
            0% {
                left: 0;
            }
            100% {
                left: 2vw;
            }
        }

.planet__d {
    width: 20vw;
    height: 20vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: planet__d_anim-init 4s ease-in-out 1 normal forwards, planet__d_anim 8s ease-in-out infinite alternate; 
}

    .planet__d div {
        width: 9vw;
        height: 9vw;
        background-color: rgba(255, 80, 0, 0.35);
    }

    .planet__c:hover .planet__inner {
        width: 10vw;
        height: 10vw;
    }

        @keyframes planet__d_anim-init {
            0% {
                width: 0vw;
                height: 0vw;
                opacity: 0;
            }
            100% {
                width: 20vw;
                height: 20vw;
                opacity: 1;
            }
        }
        @keyframes planet__d_anim {
            0% {
                left: 0;
            }
            100% {
                left: 2vw;
            }
        }


.planet__e {
    width: 50vw;
    height: 50vw;
    border-radius: 100%;
    animation: planet__e_anim-init 4s ease-in-out 1 normal forwards; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
        @keyframes planet__e_anim-init {
            0% {
                width: 100vw;
            }
            100% {
                width: 50vw;
            }
        }

        .planet__e h3 {
            position: relative;
            width: fit-content;
            margin: 0;
            font-size: 2.5vw;
            font-weight: 0;
            font-family: monospace;
            animation: pulsate 5s infinite alternate; 
            color: #fff;       
        }

        .planet__e h4 {
            font-size: 0.8vw;
            width: fit-content;
            margin: 0;
            z-index: 1;
            color: rgb(255, 42, 0);
        }

        .welcome-box {
            display: flex;
            position: relative;
        }

.background-fixed {
    position: fixed;
    z-index: -100;
    width: 100vw;
    height: 100vh;
    top:0;
    left: 0;
    background: url('../Images/daniel-olah-EarthSpace.jpg');
    background-size: 60% auto;
    background-position: 50% 35%;
    transition: 0.2s;
    animation: Main-screen-loop 15s ease-in-out infinite alternate;
    }

    @keyframes Main-screen-loop {
        0% {
            transform: scale(1);
            background-position: 50% 35%;
        }
        100% {
            transform: scale(1.1);
            background-position: 50% 25%;
        }
    }


.a_link {
    text-decoration: none;
    font-weight: 500;
    color: rgb(32, 32, 32);
}

.a_link:active, .a_link:visited {
    color: rgb(32, 32, 32);
}


.ispace_logo {
    width: 250px;
}

.isar_logo {
    width: 340px
}

.welcome {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 90vh;
    min-height: 90vh;
    color: white;
}

.welcome-bottom {
    display: flex;
    align-items: center;
}

.welcome-bottom a {
    width: 10vw;
    margin: 0 1vw
}

.scrolldown-btn {
    outline: none;
    border: none;
    width: 25px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0);
    border-left: 3px solid white;
    border-bottom: 3px solid white;
    cursor: pointer;
    margin-top: 10vh;
    transform: rotate(-45deg);
    transition: 0.2s;
}

.scrolldown-btn:hover {
    border-left: 3px solid  rgb(255, 42, 0);
    border-bottom: 3px solid  rgb(255, 42, 0);
}

.section_wrapper {
    width: 70vw;
    font-weight: 400;
    scroll-margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section_wrapper-top {
    position: relative;
    z-index: 3;
    margin: 10vh 0;
    border: 0;
    box-shadow: none;
    }
.block_wrapper {
    display: flex;
    align-items: end;
}
.section_wrapper-startups {
    min-height: 300px;
    border-radius: 25vw;
    border-left: 0.3vw solid rgb(255, 42, 0);
    border-right: 0.3vw solid rgb(255, 42, 0);
    box-shadow: 0 0 25px rgba(50, 50, 50, 0.75);
}

.section_wrapper-startups h4 {
    position: relative;
    width: fit-content;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 0;
    font-family: monospace;
    animation: pulsate 5s infinite alternate; 
    color: #fff;  
}

.section_wrapper-startups h5 {
    margin:0;
    color: rgb(255, 42, 0);
}

.section_wrapper-startups-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.startups-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.section_block {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 200;
    border-radius: 10vw;  
    padding: 0 4vw !important
}

.heading_wrapper-r {
    width: 50%;
    text-align: right;
}

.heading_wrapper-l {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}

.heading {
    display: inline-block;
    color: rgb(255, 42, 0);
    font-size: 3rem;
    margin: 1vh 0 0 0;
    letter-spacing: 1rem;
}

.heading-about {
    margin-right: 20px;
}

.heading-logo {
    font-size: 4.5rem;
    position: relative;
    bottom: -10vh      
}

.section_block-about {
    flex-direction: column;
    color: white;
    position: relative;
    z-index: 1;
    line-height: 1.7rem;
    width: 100%;
    border-top: 0.2vw solid rgb(255, 255, 255);
    border-left: 0.2vw solid rgb(255, 255, 255);
    background: rgb(255,80,0);
    background: linear-gradient(110deg, rgba(255,80,0,0.7) 0%, rgba(255,80,0,0.6) 35%, rgba(255,80,0,0.5) 75%, rgba(255,255,255,0) 100%);
}

    .section_wrapper-video p {
        padding: 1rem 2.5rem;
        line-height: 1.5rem;
        color: white;
        border-top: 2px solid rgb(255, 42, 0);
        background: rgb(255,255,255);
        background: linear-gradient(-15deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.2) 60%, rgba(255,255,255,0.2) 80%, rgba(255,255,255,0.2) 100%);
        border-radius: 10vw;
        margin-top: 10vh;
        box-shadow: 0 -5px 20px rgba(50, 50, 50, 0.75);

    }

    .section_block-video {
        flex-direction: column;
        position: relative;
        z-index: 1;
    }

    .section_block-video h4 {
        text-align: right;
        transform: scale(2);
        margin-bottom: 5vh;
    }

        .video__nav {
            width: 50vw;
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
        }

        .video__nav button {
            width: 33.3%;
            height: 32px;
            cursor: pointer;
            border: none;
            background: rgba(255,255,255,0);
            color: rgb(255, 42, 0);
            font-weight: 700;
            font-size: clamp(12px,16px,2vw);   /*clamp(min, val, max)*/
            border-radius: 5px 5px 0 0;
            transition: 0.5s;
        }

        .video__selected {
            border-top: 0.2vw solid rgb(255, 42, 0) !important;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
            transition: 0.2s;
        }

        .video__nav button:hover {
            background: rgba(255,255,255,0.1)
        } 

.video__wrapper {
    margin-bottom: 5vh;
    width: 50vw;
    height: 60vh;
    border-radius: 0 0 30px 30px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: start;
    z-index: 1;
}

.block-video-background {
    position: absolute;
    z-index: 0;
    right: -10vw;
    width: 65vw;
    height: 65vw;
    border-radius: 65vw;
    border-bottom: 0.3vw solid rgb(255, 42, 0);
    border-right: 0.3vw solid rgb(255, 42, 0);
    box-shadow: 0 0 25px rgba(50, 50, 50, 0.75);
}

.section_wrapper-story {
    scroll-margin-top: 100px;
    font-weight: 100;
    line-height: 1.7rem;
    width: 100vw;
    display: flex;
    justify-content: center; 
    position: relative;
    margin-top: 5vh;
    z-index: 3;
}

    .section_block-story {  
        color: white;
        width: 35vw;
        min-width: 500px;
        height: 35vw;
        min-height: 500px;
        border-radius: 35vw;
        box-shadow: 0 0 25px black;
        box-sizing: border-box;
        margin-left: -15vw;
        border-bottom: 0.3vw solid rgb(255, 42, 0);
        background: url('../Images/daniel-olah-EarthSpace.jpg');
        background-size: 150% auto;
        background-position: 50% 20%;
        transition: 0.2s;
        animation: section_block-story-loop 15s ease-in-out infinite alternate;
  }

    .section_block-story:before {
        content: '';
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.05) 50%, rgba(255,255,255,0.2) 85%);
    }

    .section_block-spectrum {  
        color: white;
        width: 35vw;
        min-width: 400px;
        height: 35vw;
        min-height: 400px;
        border-radius: 35vw;
        box-shadow: 0 0 25px black;
        box-sizing: border-box;
        border-bottom: 0.3vw solid rgb(255, 42, 0);
        background: url('../Images/daniel-olah-EarthSpace.jpg');
        background-size: 150% auto;
        background-position: 50% 20%;
        transition: 0.2s;
        animation: section_block-story-loop 15s ease-in-out infinite alternate;
  }

    .section_block-spectrum:before {
        content: '';
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.05) 50%, rgba(255,255,255,0.2) 85%);
    }


    @keyframes section_block-story-loop {
        0% {
            background-position: 50% 22%;
        }
        100% {
            background-position: 50% 28%;
        }
        }

    .section_wrapper-module {
        scroll-margin-top: 400px;
        font-weight: 100;
        line-height: 1.7rem;
        width: 100vw;
        display: flex;
        justify-content: center; 
        position: relative;
        z-index: 4;
    }

    .section_block-module {  
        color: rgb(0, 0, 0);
        width: 30vw;
        min-width: 450px;
        height: 30vw;
        min-height: 450px;
        border-radius: 30vw;
        box-shadow: 0 0 25px black;
        box-sizing: border-box;
        border-left: 0.3vw solid rgb(255, 255, 255);
        border-top: 0.3vw solid rgb(255, 255, 255);
        background: url('../Images/daniel-olah-EarthSpace.jpg');
        background-size: 160% auto;
        background-position: 50% 60%;
        transition: 0.2s;
        position: relative;
        right: -18vw;
        top: -20vh;
        animation: section_block-module-loop 15s ease-in-out infinite alternate;    
    }

    .section_block-module:before {
        content: '';
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.7) 85%);
    }


    @keyframes section_block-module-loop {
        0% {
            background-position: 0% 48%;
        }
        100% {
            background-position: 0% 52%;
        }
        }

.section_block-text {
    position: relative;
    font-size: 0.9rem;
    font-weight: 300;
    z-index: 2;
}

.section_block-text-module {
    color: rgb(20, 20, 20);
    font-weight: 600;

}

.section_author {
    width: 100vw;
    min-height: 40vh;
    line-height: 1.7rem;
    display: flex;
    justify-content: center;
    margin-bottom: 5vw;
    position: relative;
}

.section_block-author {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60vw;
}

.section_block-author h4 {
    text-align: right;
    color: rgb(255, 42, 0);
    font-size: 2.5vw;
    margin: 0
}

.section_block-author  p {
    color: white;
    border-left: 0.2vw solid rgb(255, 255, 255);
    border-right: 0.2vw solid rgb(255, 255, 255);
    background: rgb(255,80,0);
    background: linear-gradient(110deg, rgba(255,80,0,0.7) 0%, rgba(255,80,0,0.6) 35%, rgba(255,80,0,0.5) 75%, rgba(255,255,255,0) 100%);
    box-shadow: 0 0 30px black;
    padding: 2rem;
    border-radius: 60vw;
}

.link_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.link_box-story {
    position: absolute;
    z-index: 5!important; 
    top: 25vh;
    left: -12vw;
    transform: rotate(-10deg);
    animation: link_story-highlight;
    animation-duration: 20s;
    animation-iteration-count: infinite;
}

.link_box-module {
    position: absolute;
    z-index: 50!important; 
    top: -5vh;
    right: -8vw;
    transform: rotate(-10deg);
    animation: link_module-highlight;
    animation-duration: 25s;
    animation-iteration-count: infinite;
}

.link-big {
    transform: scale(3.5);
}


@keyframes link_story-highlight {
    0% {
        transform: translate(0,0);
    }

    40%{
        transform: translate(5px,-15px) rotate(-10deg);
    }

    80% {
        transform: translate(2px,-5px) rotate(5deg);
    }
    100% {
        transform: translate(0,0);
    }

}

@keyframes link_module-highlight {
    0% {
        transform: translate(0,0);
    }

    45%{
        transform: translate(-5px,-12px) rotate(-10deg);
    }

    78% {
        transform: translate(-2px,-7px) rotate(5deg);
    }
    100% {
        transform: translate(0,0);
    }

}

@keyframes Logo-flight {
    0% {
        top: 0%;
        left: 0%;
        transform: rotate(15deg) scale(1);
        z-index: 3;
    }
    2% {
        top: 5%;
        left: 5%;
        transform: rotate(10deg) scale(1);
        z-index: 1;
    }
    10% {
        top: 50%;
        left: 20%;
        transform: rotate(-15deg) scale(0.7);
        z-index: 1;
    }
    25% {
        top: 10%;
        left: 40%;
        transform: rotate(20deg) scale(1);
        z-index: 1;
    }

    27% {
        top: 12%;
        left: 35%;
        transform: rotate(17deg) scale(0.9);
        z-index: 3;
    }

    40% {
        top: 70%;
        left: 70%;
        transform: rotate(-15deg) scale(0.8);
        z-index: 3;
    }

    42% {
        top: 70%;
        left: 75%;
        transform: rotate(-5deg) scale(0.8);
        z-index: 1;
    }

    65% {
        top: 20%;
        left: 90%;
        transform: rotate(-15deg) scale(0.5);
        z-index: 1;
    }

    85% {
        top: 50%;
        left: 50%;
        transform: rotate(0deg) scale(0.7);
        z-index: 1;
    }

    100% {
        top: 0%;
        left: 0%;
        transform: rotate(15deg) scale(1);
        z-index: 1;
    }

}

@keyframes pulsate {
    100% {
      /* Larger blur radius */
      text-shadow:
      0 0 0px #fff,
      0 0 0px rgb(179, 179, 179)


    }
   0% {
      /* A slightly smaller blur radius */
      text-shadow:
      0 0 6px #fff,
      0 0 24px rgb(179, 179, 179)
    }
}

.photo-by {
    font-size:0.8rem;
    padding: 10px;
    border-radius: 10px 10px 0 0 ;
    background-color: rgba(255, 255, 255, 0.75); 
}

/*LINKS*/
  .link_home-module {
    text-decoration: none;
    text-align: center;
    display: inline-block;
    width: 4vw;
    max-width: 80px;
    height: 4vw;
    max-height: 80px;
    min-width: 30px;
    min-height: 30px;
    font-size: clamp(7px,0.9vw, 24px); 
    line-height:clamp(30px,4vw, 80px); 
    border-radius: 100%;
    color: white;
    background: url(../Images/link-moon.png);
    background-repeat: no-repeat;
    background-size: 48%;
    background-position: right;
    font-weight: 600;
    letter-spacing: 0.7px;
    transition: 0.5s;
    border: none;
    cursor: pointer;
    position: relative;
    z-index: 10;
  }

  .link_home-module:hover {
    background-color: rgba(255, 80, 0, 0.5);
    letter-spacing:normal;
    background-image: none;
    background-size: 200%;
    background-position: center;
  }
  
  .link_home-module:after {
    content: '3D';
    display: inline;
    padding:0;
    line-height: clamp(14px,1.4vw, 1.4vw); 
    font-size: clamp(6px,0.6vw, 0.6vw); 
    bottom: 15%;
    left: -20%;
    opacity: 1;
    width: 30%;
    height: 30%;
    min-width: 30%;
    min-height: 30%;
    background-color: rgba(241,90,36);
    border-top: 0vw solid rgb(255, 42, 0);
    border-bottom: 0vw solid rgb(255, 42, 0);
    transform: rotate(-10deg);
    position: absolute;
    border-radius: 100%;
    z-index: -10;
    opacity: 1;
    transition: 0.5s;
  }
  
  .link_home-module:hover:after{
    content: '';
    position: absolute;
    bottom: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    border-radius: 100%;
    min-width: 34px;
    min-height: 34px;
   /* border-top: 0.2vw solid rgb(255, 42, 0);
    border-bottom: 0.2vw solid rgb(255, 42, 0)*/;
    z-index: -10;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.5s;   
    background-color: rgba(241,90,36,0);
    transform: rotate(-10deg);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
   }
  
  .link_home-module:before {
    content: '';
    position: absolute;
    top: -5%;
    left: -5%;
    width: 120%;
    height: 120%;
    z-index: -10;
    background: url(../Images/link-module.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    opacity: 1;
    transform: scale(1.1) translateX(-0.9vw) rotate(-5deg);
    transition: 1s;
  }
  
  .link_home-module:hover:before {
    opacity: 0;
    transform: scale(0.1) translateX(-2vw) rotate(10deg);
   }
  
   .link_home-story {
    background: url(../Images/link-moon.png);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;
    font-weight: 600;
    letter-spacing: 0.7px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    width: 4vw;
    max-width: 80px;
    height: 4vw;
    max-height: 80px;
    min-width: 30px;
    min-height: 30px;
    font-size: clamp(7px,0.9vw, 24px);
    line-height:clamp(30px,4vw, 80px); 
    border-radius: 100%;
    color: white;
    transition: 0.5s;
    cursor: pointer;
    position: relative;
  }
  
  .link_spectrum-story:hover {
    background-color: rgba(255, 80, 0, 0.5);
    background-image: none;
    background-size: 200%;
    font-weight: 600;
    letter-spacing: normal;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 1);
  }
    
  .link_home-story:before {
    content: '';
    position: absolute;
    top: -40%;
    left: -30%;
    width: 170%;
    height: 170%;
    z-index: -10;
    background: url(../Images/link-story-white.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -8;
    opacity: 1;
    transform: scale(1.1) translateX(0) rotate(0);
    transition: 0.5s;
  }

  .link_home-story:hover:before {
        opacity: 0;
        transform: scale(0.8) translateX(-2vw) rotate(-5deg);
   }

  .link_home-story:after {
    opacity: 1;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.8) 85%);;
    transform: rotate(90deg);
    border-color: rgba(241,90,36);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    min-width: 34px;
    min-height: 34px;
    z-index: -9;
    transition: 0.5s;
  }
    
   .link_home-story:hover:after {
    opacity: 1;
    background: transparent;
    transform: rotate(0deg);
    border-color: rgba(255, 42, 0,1);
    top: -10%;
    left: -12%;
    width: 120%;
    height: 120%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
   }

   .link_spectrum {
    background: url(../Images/link-earth.png);
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;
    font-weight: 600;
    letter-spacing: 0.7px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    width: 4vw;
    max-width: 80px;
    height: 4vw;
    max-height: 80px;
    min-width: 30px;
    min-height: 30px;
    font-size: clamp(7px,0.7vw, 20px);
    line-height:clamp(30px,4vw, 80px); 
    border-radius: 100%;
    color: white;
    transition: 0.5s;
    cursor: pointer;
    position: relative;
  }
  
  .link_spectrum:hover {
    background-color: rgba(255, 80, 0, 0.5);
    background-image: none;
    background-size: 200%;
    font-weight: 600;
    letter-spacing: normal;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 1);
  }
    
  .link_spectrum:before {
    content: '';
    position: absolute;
    top: -40%;
    left: -30%;
    width: 170%;
    height: 170%;
    z-index: -10;
    background: url(../Images/link-spectrum-white.png);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -8;
    opacity: 1;
    transform: scale(1.1) translateX(0) rotate(0);
    transition: 0.5s;
  }

    .link_spectrum:hover:before {
        opacity: 0;
        transform: scale(0.8) translateX(-2vw) rotate(-5deg);
   }

  .link_spectrum:after {
    opacity: 1;
    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.8) 85%);;
    transform: rotate(90deg);
    border-color: rgba(241,90,36);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    min-width: 34px;
    min-height: 34px;
    z-index: -9;
    transition: 0.5s;
  }
    
   .link_spectrum:hover:after {
    opacity: 1;
    background: transparent;
    transform: rotate(0deg);
    border-color: rgba(255, 42, 0,1);
    top: -10%;
    left: -12%;
    width: 120%;
    height: 120%;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.75);
   }



/* LINKS END */



  @media (max-width: 801px){
         .home {
            font-size: 0.9rem;
         }

         .background-fixed {
            position: fixed;
            z-index: -100;
            top:0;
            left: 0;
            background: url('../Images/daniel-olah-EarthSpace.jpg');
            background-size:cover;
            background-position: 50% 35%;
        }

        .section_nav {
            padding: 1vh 1vw 1vh 1vw;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.8) 85%, rgba(0,0,0,0) 100%);
        }

        .nav-box button {
            width: 5vw;
            height: 5vw;
            border-radius: 5vw;
            margin: 0 2.5vw;
            font-size: 0.45rem;
        }
    
        .nav-box button:hover {
            width: 18vw;
        }

        .welcome h3 {
            position: relative;
            width: fit-content;
            margin: 0;
            font-size: 3;
            z-index: 2;
            font-weight: 0;
            font-family:monospace;
            animation: pulsate 5s infinite alternate; 
            color: #fff;
        }
        
        .welcome h4 {
            font-size: 2.8vw;
            width: fit-content;
            margin: 0;
            z-index: 1;
            color: rgb(255, 42, 0);
        
        }

    .section_top {
        justify-content: center;
        min-height: 70vh;
    }

    .planet{
         display: none;
    }

    .planetX {
        display: flex !important
    }

    .planet__a {
        margin-top: 10px;
    }

    .planet__e {
        width: 100vw;
        height: 100vw;
    }
    .planet__e h3 {
        font-size: 2.5rem;
    }

    .welcome-bottom {
        flex-direction: column;
        justify-content: center;
        width: 90vw;
        margin-top: 5vh;
    }

    .welcome-bottom h4 {
        font-size: 0.8rem;
    }

    .welcome-bottom a {
        width: 30vw;
    }

    .scrolldown-btn {
        border-color: rgb(255, 42, 0);
    }

    .section_about {
        clip-path: polygon(0 0, 100% 4%, 100% 100%, 0 96%);
        margin-bottom: 20%;
        padding: 15% 0
    }

    .section_wrapper {
        width: 95vw;
    }


    .section_wrapper-startups-list {
        flex-direction: column;
        justify-content: space-around;
    }
    
    .block_wrapper {
        flex-direction: column;
    }


    .section_block-about {
        border-right: none;
        width: 90%;
    }

    .heading_wrapper-l {
        flex-direction: column;
        text-align: center;
    }

    .heading_wrapper-r {
        width: 100%;
        text-align: center;
    }

    .heading-logo {
        bottom:0;
        font-size: 2rem;
        margin-top: 5vh;
    }

    .heading-about {
        font-size: 2rem;
        display: flex;
        align-items: center;
    }

    .section_wrapper-video p {
        margin-top: 0;
        padding: 1rem 1.5rem;
        background: linear-gradient(-15deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 60%, rgba(255,255,255,0.3) 80%, rgba(255,255,255,0.4) 100%);
    }

    .block-video-background {
        display: none
    }

    .video__nav {
        width: 98vw;
    }

    .video__wrapper {
        width: 98vw;
        height: fit-content;
        border-radius: 0;
    }

    .section_author {
        padding: 10vh 0
    }

    .section_block-author {
        border-left: none;
        width: 90vw;
    }
    
    .section_block-author h4 {
        font-size: 2rem;
    }

    .section_block-author p {
        border-radius: 10vw;
    }

    .section_block {
        flex-direction: column;
    }

    .section_block-text {
        padding: 1vw;
        box-sizing: border-box;
        width: 100%
    }

    .link_box {
        width: 100% ;
        margin-bottom: 5%; 
   
    }

    .link_box-story {
        transform: rotate(0deg);
        position: relative;
        top:0;
        left:0
    }
    
    .link_home-story:after {
        min-height: 5px;
        min-width: 5px;
    }

    .link_box-module {
        transform: rotate(0deg);
        position: relative;
        top:0;
        left:0
    }


    .section_block-story, .section_block-spectrum {  
        display: flex;
        animation: none;
        min-width: 98vw;
        margin-left: 0;
        height: 70vh;
        justify-content: center;
        border-bottom: 0;
        border-left: 0.3vw solid rgb(255, 42, 0);
        border-right: 0.3vw solid rgb(255, 42, 0);
        border-radius: 12vw;   
        background-position: 50% 0%;
    }

    .section_block-story:before,.section_block-spectrum:before {
        min-width: 98vw;
        border-radius: 12vw;
    }

    .section_wrapper-module {
        margin-top: 10vh
    }

    .section_block-module { 
        position: relative;
        top: 0;
        right: 0;
        min-width: 98vw;
        height: 70vh;
        border-radius: 12vw;  
        animation: none;
        justify-content: center;
    }

    .section_block-module:before {
        min-width: 98vw;
        border-radius: 12vw;
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 30%, rgba(255,255,255,0.5) 35%, rgba(255,255,255,0.8) 85%, rgba(255,255,255,85) 100%);
    }

    .section_block-text-module {
        font-size: 1rem;
        font-weight: 600;
    }

    .section_author{
        margin-top: 10vh;
        top: 0;
    }

    .section_block-author p {
        border-left: 0;
        border-right: 0;
        border-bottom: 0.5vw solid rgb(255, 42, 0);
        background: linear-gradient(110deg, rgba(255,80,0,0.85) 0%, rgba(255,80,0,0.75) 35%, rgba(255,80,0,0.65) 85%, rgba(255,255,255,0) 100%);
        font-weight: 300;
    }
  }